// 通过订单编号查看订单详情(取消订单详情相同)-酒店http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/queryHotelOrderDetailsUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_queryHotelOrderDetailsForOrderCenter = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/queryHotelOrderDetailsForOrderCenter',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_queryHotelOrderDetailsForOrderCenter;