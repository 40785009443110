
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '订单详情',
    }
  },
  data() {
    return {

    };
  },
  computed: {
    // currentPage () {
    //   const type = this.$route.query.listType;
    //   if (type === '1') {
    //     return this.$store.state.hotelListCurrentPage;
    //   } else if (type === '2') {
    //     return this.$store.state.hotelUnsubscribeCurrentPage;
    //   }
    // }
  },
  methods: {
    to_list () {
      this.$router.back();
      // const type = this.$route.query.listType;
      // if (type === '1') {
      //   this.$router.push({
      //     name: 'admin-hotel-order-list',
      //     query: {
      //       currentPage: this.currentPage,
      //     }
      //   })
      // } else if (type === '2') {
      //   this.$router.push({
      //     name: 'admin-hotel-unsubscribe-list',
      //     query: {
      //       currentPage: this.currentPage,
      //     }
      //   })
      // }
    }
  },
};
